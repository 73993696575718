import { BestBuy } from './BestBuy'
import { BrookSource } from './BrookSource'
import { IndependentConsulting } from './IndependentConsulting'
import { MaverickSoftwareConsulting } from './MaverickSoftwareConsulting'
import { Optum } from './Optum'
import { OptumRx} from './OptumRx'
import { PalletGeo } from './PalletGeo'
import { ThomsonReuters } from './ThomsonReuters'

const Jobs = [
  PalletGeo,
  OptumRx,
  BestBuy,
  IndependentConsulting,
  Optum,
  BrookSource,
  ThomsonReuters,
  MaverickSoftwareConsulting
]

export default Jobs
